
import React from 'react'
import './mapLocation.scss'

const MapLocation = () => {

    return (
        <div className="feature map">
            <iframe title='Google maps location' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12360.578587927726!2d-84.4613509!3d39.239588!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa3ac709690e83963!2sValley%20Refrigeration%20%26%20AC%20Services!5e0!3m2!1sen!2sus!4v1614350475770!5m2!1sen!2sus" height="600" allowFullScreen="" loading="lazy"></iframe>
        </div>
    )
}

export default MapLocation
